import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Logo } from "../components/svg"
import { Grid } from "../styles/global"

const FourOhFour = styled(Grid)`
  grid-template-columns: 140px 140px 140px;
`


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    {/*
      <FourOhFour>
      <Logo letter={'four'} />
      <Logo letter={'zero'} />
      <Logo letter={'four'} />
    </FourOhFour>
    */}
    <p>404</p>
    <p>Sorry we unable to find what you're looking for.</p>
  </Layout>
)

export default NotFoundPage
